.call_icon {
  animation: logoanimation 0.2s ease-in-out infinite;
}

@keyframes logoanimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
